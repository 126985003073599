$green: #28a038;
$theme-colors: (
    'primary': #06026d,
    'secondary': #007aff,
    'success': #6ce14a,
    'info': #7938fe,
    'danger': #ff4136,
    'green': #3ace01,
    'light-green': #d7f5cc,
    'tim': #44317b,
    'cancel': #6c757d,
);
@import 'node_modules/bootstrap/scss/bootstrap';

@import url('assets/styles/font.scss');

.cursor-pointer {
    cursor: pointer;
}

.bg-cancel-gray {
    background-color: #ececec !important;
}

#admin-wrapper {
    table.product-table {
        background-color: #fff;

        tbody {
            td {
                font-size: 16px;

                .product-image {
                    width: 88px;
                    height: 88px;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }
    }
}

.fz14 {
    font-size: 14px !important;
}

.fz16 {
    font-size: 16px !important;
}

table.product-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 7px;

    tbody {
        td {
            border: 1px solid #707070;
            padding: 7px;
            font-size: 12px;
            text-align: left;
            white-space: pre-line;
        }
    }
}

.simple-style {
    width: 60px;
    display: inline-flex !important;
    padding: 0px 5px;

    input {
        border-radius: 0 !important;
        border: none;
        border-bottom: 1px solid #000 !important;
        background: none !important;
        height: 30px !important;
        padding: 0px !important;

        &:focus {
            box-shadow: none !important;
        }
    }
}

.text-underline {
    text-decoration: underline;
}

.modal-90w {
    width: 95%;
    height: 95%;
    margin: 10px auto;
    max-width: none !important;

    .modal-content {
        height: 100%;
    }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px !important;
    padding-top: 5px;
    box-shadow: 02px 5px 10px 0 #9191911f;
}

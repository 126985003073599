.container-project2 {
    width: 100%;
    background-color: #e3e6e8;
    padding: 28px;
}

.project-content-border2 {
    min-height: calc(100% - 56px);
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    padding: 28px;
    overflow: scroll;
}
.null-button-next {
    display: none !important;
}
.wrapper-class {
    padding: 0 !important;
    margin-top: 16px;
}
.step-class span {
    height: 40px !important;
    width: 40px !important;
}
.step-class span svg {
    height: 20px !important;
    width: 20px !important;
}
.label-class {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #58606a;
}

._35Ago span {
    background-color: #215493 !important;
}
.height25 {
    height: 25% !important;
}
.text-table {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1c1e21;
}
.tab-project {
    width: 100%;
    height: 42px;
    margin-top: 24px;
    margin-bottom: 15px;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: 1px solid #cdd1d5;
}

.tab-project div {
    padding: 0px 20px 3px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #58606a;
    cursor: pointer;
}
.tab-active {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #215493 !important;
    border-bottom: 2px solid #215493;
}

.table-chart-project {
    width: 100%;
    height: 81px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.option-table-chart-project {
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.chart-button {
    width: 140px;
    height: 42px;
    justify-content: center;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #58606a;
}

.table-button {
    width: 88px;
    height: 42px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #58606a;
}

.active-chart {
    background: rgba(33, 84, 147, 0.1);
    border-radius: 6px;
    color: #215493 !important;
}

.fix-height {
    /* height: calc(100% - 600px) !important; */
    height: 300px !important;
}

.close-button {
    width: 100%;
    height: 50px;
}
.project-inf {
    height: 224px !important;
}

.chart-container {
    width: 100%;
    /* min-height: 446px; */
}

.button-list-detail-project {
    height: 100%;
    width: 438px;
}

.chart-detail-project {
    height: 100%;
    width: calc(100% - 438px);
    /* background-color: #215493; */
}

.header-title {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    border-bottom: 1px solid #cdd1d5;
    border-right: 1px solid #cdd1d5;
    height: 160px;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 14px 12px;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.wrapper-class > ul {
    z-index: 0 !important;
}

.project-detail-inf {
    width: 100%;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
}

.project-detail-inf-image {
    width: 226px;
    border-radius: 8px;
    height: 100% !important;
    margin-right: 1rem;
    object-fit: cover;
}

.project-detail-inf-row-title {
    width: 22.5225%;
    background: #c7dcf5;
    border-bottom: 1px solid #f5f5f5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 12px;
}
.project-detail-inf-row-title-sp {
    width: 60px;
    background: #c7dcf5;
    /* border-bottom: 1px solid #f5f5f5; */
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 4px;
}

.project-detail-inf-row-title-sp-2 {
    /* width: 174px; */
    background: #c7dcf5;
    /* border-bottom: 1px solid #f5f5f5; */
    border: 1px solid #F0F0F0;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 4px;
}

.project-detail-inf-row-title-sp-3 {
    background: #c7dcf5;
    /* border-bottom: 1px solid #f5f5f5; */
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 4px;
    border: 1px solid #F0F0F0;
}

.project-detail-inf-row-content {
    width: 77.477%;
    border-bottom: 1px solid #f5f5f5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 12px;
}
.project-detail-inf-row-content-sp {
    width: calc(100% - 60px);
    border-bottom: 1px solid #f5f5f5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 4px;
}

.project-detail-inf-row-content-sp-2 {
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    padding-left: 4px;
}

.project-detail-inf-row {
    width: 100%;
    min-height: 56px;
    height: auto;
}

.project-detail-inf-row-sp {
    width: 100%;
    min-height: 45px;
    height: auto;
}

.project-detail-inf-row:first-child
    > .project-detail-inf-row-title:first-child {
    border-top-left-radius: 5px;
}

.project-detail-inf-row:last-child > .project-detail-inf-row-title:first-child {
    border-bottom-left-radius: 5px;
}

.project-detail-inf-row:last-child > .project-detail-inf-row-title {
    border-bottom: none;
}

.project-detail-inf .project-detail-inf-row .cooperative-companies-list {
    padding: 8px 0;
}

.project-detail-footer {
    width: 100%;
    height: 70px;
    justify-content: flex-end;
    align-items: flex-end;
}

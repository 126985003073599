@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Zen+Kaku+Gothic+Antique:wght@500&display=swap');

.App {
    text-align: center;
}

.main {
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
}

.content-body {
    /* height: 100%; */
    width: calc(100% - 242px);
    /* overflow: auto; */
    background-color: #e3e6e8;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.error {
    color: #f44336;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.d-flex {
    display: flex !important;
    display: -webkit-flex;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-none {
    display: none !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.highcharts-yaxis-labels > text {
    color: #000 !important;
    fill: #000 !important;
}

/* Schedules */
.react-datepicker-popper {
    z-index: 9999 !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    box-shadow: 0px 15px 32px rgb(90 92 105 / 100%);
    background: #ffffff;
    color: #74869a;
    font-weight: 500;
    padding: 0;
    min-width: 200px;
    font-size: 13px;
}

.MuiTooltip-popper .MuiTooltip-tooltip button {
    background: none;
    border: 0;
    width: 100%;
    padding: 6px 10px;
    text-align: left;
}

.MuiTooltip-popper .MuiTooltip-tooltip .MuiTooltip-arrow {
    color: #ffffff;
}

.customBootstrapDialog.deleteConfirmationDialog .MuiPaper-root {
    border-radius: 12px;
    padding: 28px;
    min-height: 152px;
}

.customBootstrapDialog.deleteConfirmationDialog
    .MuiPaper-root
    .MuiDialogContent-root {
    padding: 0;
}

.customBootstrapDialog.deleteConfirmationDialog .MuiDialogActions-root {
    padding: 0;
    padding-top: 24px;
}

.customBootstrapDialog.scheduleFormDialog .MuiPaper-root {
    border-radius: 12px;
}
/* End Schedules */

.MuiAutocomplete-popper {
    z-index: 10000 !important;
}

@media only screen and (max-width: 992px) {
    .content-body {
        width: 100%;
        /* background-color: #fff; */
    }
}

@media only screen and (max-width: 638px) {
    .customBootstrapDialog.scheduleFormDialog .MuiPaper-root {
        width: 100%;
        margin: 0px;
        max-width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .MuiDialog-scrollPaper {
        flex-direction: column !important;
        justify-content: end !important;
    }
}

.back-step-edit-button {
    width: 100px;
    height: 40px;
    border: 1px solid rgba(33, 84, 147, 0.3);
    border-radius: 4px;
}

.custom-swal-title-class {
    font-size: 22px !important;
    font-weight: 700 !important;
}

.custom-swal-icon-class.swal2-success .swal2-success-ring {
    border-color: #07a315 !important;
}

.custom-swal-icon-class.swal2-success [class^='swal2-success-line'] {
    background-color: #07a315 !important;
}

.custom-swal-confirm-btn-class {
    padding: 10px 65px !important;
    border-radius: 12px !important;
}
/* debug classname */
.test {
    border: 1px solid red;
}

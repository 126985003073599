.container-worker-create-detail {
    /* width: calc(100% - 56px);
    height: calc(100% - 120px); */
    /* height: 100%; */
    background-color: #e3e6e8;
    padding: 28px;
    /* overflow: hidden; */
    /* overflow-y: scroll; */
}

.worker-create-content {
    width: 400px;
    /* height: calc(100% - 56px); */
    background: #ffffff;
    border-radius: 12px;
    padding: 28px;
    /* overflow: auto; */
}

.worker-create-title {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1c1e21;
    margin-bottom: 24px;
}

.margin-input {
    margin-bottom: 12px;
}
.margin-input p {
    margin-top: 0px;
}

.input-date {
    width: 100%;
}
.input-date-img {
    position: absolute;
    right: 15px;
    top: 10px;
}

.possition-relative {
    position: relative;
}

.absolute-bottom {
    position: absolute;
    right: 15px;
    bottom: 10px !important;
}

.worker-create-search-select-style {
    width: 100% !important;
    height: 36px !important;
    padding: 0px !important;
}

.worker-create-search-select-style .MuiOutlinedInput-root {
    height: 36px;
    padding: 0px !important;
}
.worker-create-search-select-style .autocomplete-custom-textfield input {
    border: none;
    padding-left: 10px !important;
}

.worker-create-search-select-style .MuiAutocomplete-root {
    height: 36px;
    padding: 0px !important;
}

.worker-create-search-select-style fieldset {
    height: 42px !important;
    border-radius: 8px;
}

.worker-create-input {
    width: auto;
}

.create-button-worker {
    max-width: 343px;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

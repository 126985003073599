.chart-custom {
  text {
    font-size: 15px;
    margin-bottom: 10px !important;
    padding: 20px !important;
  }
    rect {
    border-radius: 15px !important;
  }

  rect + text {
    border-radius: 15px !important;
  }
}